<script setup>
import { computed, ref, useNuxtApp } from '#imports';
import { useUserStore } from '@/stores/user';
import { useAdult } from '@/composables/useAdult';
import { commonAnalytic } from '@/analytic/common';

import UiButton from '@/components/ui/button/UiButton.vue';

const emit = defineEmits(['change']);

const adultComposable = useAdult();
const userStore = useUserStore();
const { $tp } = useNuxtApp();

const adult = ref(undefined);
const localeAdult = ref(undefined);

checkAdultSectionState();
function checkAdultSectionState() {
  adult.value = adultComposable.isAdult();
}
const isMainHeading = computed(() => {
  return adult.value !== false || localeAdult.value === undefined;
});
function toggleAdultValue(value) {
  adult.value = value;
  localeAdult.value = value;

  adultComposable.setAdultValue(value);

  userStore.setAdult(value);

  emit('change', value);

  commonAnalytic.ageVerification(value);
}
</script>
<template>
  <div class="adult-section">
    <div class="adult-section__heading">
      <transition name="fade" mode="out-in">
        <h1
          v-if="isMainHeading"
          class="adult-section--heading adult-section__font--heading"
        >
          {{ $tp('добро пожаловать на winston.ru') }}
        </h1>

        <h1 v-else class="adult-section--heading adult-section__font--heading">
          {{ $tp('сайт не доступен') }}
        </h1>
      </transition>
    </div>

    <transition name="fade" mode="out-in">
      <div :key="`${adult}-${localeAdult}`" class="adult-section__description">
        <p
          v-if="adult"
          class="adult-section__font adult-section__font--description"
        >
          {{
            $tp(
              'Здесь вы сможете узнать о продуктах, последних новинках и погрузиться в историю бренда',
            )
          }}
        </p>

        <div
          v-else-if="adult === undefined || localeAdult === undefined"
          class="adult-section__adult"
        >
          <p class="adult-section__font adult-section__font--description">
            {{
              $tp(
                'вы находитесь на официальном сайте бренда винстон. этот сайт предназначен для совершеннолетних потребителей табака. подтвердите, что вам есть 18&nbsp;лет.',
              )
            }}
          </p>

          <div class="adult-section__actions">
            <UiButton
              theme="white"
              class="adult-section__button adult-section__button--is-adult"
              text="Мне есть 18 лет"
              @click="toggleAdultValue(true)"
            />

            <UiButton
              theme="second-white"
              class="adult-section__button adult-section__button--not-adult"
              text="Мне нет 18 лет"
              @click="toggleAdultValue(false)"
            />
          </div>
        </div>

        <p
          v-else-if="adult === false || localeAdult === false"
          class="adult-section__font adult-section__font--description adult-section__font--description--no-adult"
        >
          {{
            $tp(
              'Наша продукция предназначена только для пользователей, достигших возраста 18&nbsp;лет',
            )
          }}
        </p>
      </div>
    </transition>
  </div>
</template>
<style scoped lang="scss">
.adult-section {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__font {
    &--heading {
      @include h2;

      color: $color-white;

      @include media-breakpoint-down(lg) {
        font-size: em(36);
      }

      @include media-breakpoint-down(sm) {
        @include h2-mob;
      }
    }

    &--description {
      @include subtitle-m;

      color: $color-white;

      @include media-breakpoint-down(lg) {
        font-size: em(10);
      }

      @include media-breakpoint-down(sm) {
        @include subtitle-m-mob;
      }

      &--no-adult {
        @include media-breakpoint-down(sm) {
          margin-bottom: em(58, 12);
        }
      }
    }
  }

  &__heading {
    max-width: em(704);
    padding: 0 em(26);
    margin-bottom: em(70);
    text-align: center;

    @include media-breakpoint-down(lg) {
      max-width: em(460);
      margin-bottom: em(23);
    }

    @include media-breakpoint-down(sm) {
      max-width: none;
      margin-bottom: em(16);
    }
  }

  &__description {
    max-width: em(496);
    text-align: center;

    @include media-breakpoint-down(lg) {
      max-width: em(306);
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
      max-width: em(496);
      padding: 0 em(26);
    }
  }

  &__button {
    &--is-adult {
      width: calc(50% - #{em(8)});

      @include media-breakpoint-down(sm) {
        width: em(224);
      }
    }

    &--not-adult {
      width: calc(50% - #{em(8)});

      @include media-breakpoint-down(sm) {
        width: em(224);
      }
    }
  }

  &__actions {
    display: flex;
    gap: em(16);
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: em(56);

    @include media-breakpoint-down(lg) {
      max-width: em(306);
    }

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      width: 100%;
      max-width: none;
      margin-top: em(48);
      margin-bottom: em(58);
    }
  }
}
</style>
