<script setup>
import { useNuxtApp, ref, onBeforeUnmount, watch, onMounted } from '#imports';

import breakpoints from '@/configs/breakpoints';

const { $gsap } = useNuxtApp();

const props = defineProps({
  isCollapsed: {
    type: Boolean,
    default: false,
  },
  isCollapsedStart: {
    type: Boolean,
    default: false,
  },
  isEqual: {
    type: Boolean,
    default: false,
  },
});

//<editor-fold desc="Animation">
const leftRef = ref(null);
const leftContentRef = ref(null);
let matchMediaMobileHeight = null;

watch(
  () => props.isCollapsed,
  () => {
    setupHeightAnimation();
  },
);

onMounted(() => {
  if (props.isCollapsed) {
    setupHeightAnimation();
  }
});

function setupHeightAnimation() {
  matchMediaMobileHeight = $gsap.matchMedia();
  const targetHeight = !props.isCollapsed ? '17.25em' : '100vh';

  matchMediaMobileHeight.add(`(max-width: ${breakpoints.sm - 0.02}px)`, () => {
    $gsap.to(leftRef.value, {
      height: targetHeight,
      duration: 0.5,
      ease: 'power2.out',
    });
  });
}

onBeforeUnmount(() => {
  matchMediaMobileHeight?.kill();
});
// </editor-fold>
</script>

<template>
  <div
    class="side-view"
    :class="{
      'side-view--collapsed': props.isCollapsed,
      'side-view--collapsed-star': props.isCollapsedStart,
      'side-view--equal': props.isEqual,
    }"
  >
    <div class="side-view__bg">
      <slot name="background"></slot>
    </div>
    <div ref="leftRef" class="side-view__part side-view__part--left">
      <div ref="leftContentRef" class="side-view__content">
        <div class="side-view__container side-view__container--left">
          <slot name="left"></slot>
        </div>
      </div>
    </div>
    <div class="side-view__part side-view__part--right side-view__part--accent">
      <div class="side-view__content">
        <div class="side-view__container side-view__container--right">
          <slot name="right"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.side-view {
  $parent: &;

  position: relative;
  display: flex;
  flex-grow: 1;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    height: auto;
    min-height: 100vh;
  }

  &__part {
    position: relative;
    flex-grow: 1;
    height: 100%;
    overflow: hidden;
    transition: width $time-very-slow;

    @include media-breakpoint-down(sm) {
      width: 100%;
      min-width: min-content; // TODO

      //display: grid;
      //grid-template-columns: minmax(0, em(116)) auto minmax(0, em(116));
      max-width: calc(100% - #{em(116)} * 2);
      margin-right: auto;
      margin-left: auto;
    }

    &--left {
      @include hide-scroll;

      position: relative;
      display: flex;
      flex-direction: column;
      flex-grow: 0;
      flex-shrink: 0;
      align-items: center;
      width: em(480);
      height: 100%;
      padding-top: em(330);
      overflow: hidden auto;
      transition: width $time-very-slow;

      @include media-breakpoint-down(lj) {
        justify-content: center;
        padding-top: 0;
      }

      @include media-breakpoint-down(sm) {
        height: em(276); // TODO
        padding: em(58) 0 em(40); // TODO
      }
    }

    &--right {
      @include hide-scroll;

      display: flex;
      flex-direction: column;
      flex-grow: 1;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      width: 50%;
      overflow: hidden auto;

      @include media-breakpoint-down(sm) {
        flex-grow: 1;
        justify-content: initial;
        height: auto;
        overflow: initial;
      }
    }

    &--accent {
      background-color: $color-white;
    }
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    transition: width $time-very-slow;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: em(315 + 80 * 2);
    max-width: calc(100% - #{em(80)} * 2);
  }

  &__container {
    min-width: em(375);
    max-width: 100vw;
    padding: em(40) em(30);

    &--left {
      @include media-breakpoint-down(sm) {
        padding: 0; // TODO
      }
    }

    &--right {
      @include media-breakpoint-up(lg) {
        width: em(556); // TODO
        margin: 0 auto; // TODO
      }
    }
  }

  &--equal {
    #{$parent} {
      &__part {
        width: 50%;

        @include media-breakpoint-down(sm) {
          width: 100%;
        }
      }
    }
  }

  &--collapsed-star {
    #{$parent} {
      &__part--left {
        height: 100vh; // TODO
      }
    }
  }

  &--collapsed {
    @include media-breakpoint-down(sm) {
      height: 100vh;
      overflow: hidden;
    }

    #{$parent} {
      &__part--left {
        width: 100%; // TODO
      }

      &__bg {
        width: 100%; // TODO
      }
    }
  }
}
</style>
